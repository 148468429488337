import 'whatwg-fetch'
import 'ts-polyfill'
import 'proxy-polyfill'

import * as React from 'react'
import { render } from 'react-dom'

import App from './App'

export const renderApp = () => {
  render(<App />, document.getElementById('app'))
}

renderApp()
