import * as React from 'react'
import styled, { keyframes } from 'styled-components'

import THEME from '@debbie/iris/dist/theme'

export const Loader = () => (
  <Wrapper>
    <Svg
      version="1.1"
      id="b3d864f8-84bf-4ad6-ac45-a7174feef3c9"
      x="0px"
      y="0px"
      viewBox="0 0 1000 661"
    >
      <path
        style={{
          fill: 'none',
          stroke: '#2F2E41',
          strokeMiterlimit: 10,
        }}
        d="M381.9,191.4c43.9,9.5,91.9,25.9,140.2,49.1c35,16.7,68.5,36.3,100.1,58.8"
      />
      <G>
        <path
          style={{ fill: '#230A58' }}
          d="M363.7,277.5v-87h87c96.8,0,174.1,78.2,174.1,174.1c0,96.8-78.2,174.1-174.1,174.1h-87v-87h87
            c47.9,0,87-39.1,87-87s-39.1-87-87-87H363.7z"
        />
        <path
          style={{ fill: '#7865FF' }}
          d="M363.7,277.5h-87v174.1h87V277.5z"
        />
      </G>
      <path
        style={{
          fill: 'none',
          stroke: '#2F2E41',
          strokeMiterlimit: 10,
        }}
        d="M569.3,224.4c10.1,5.3,19.9,10.8,29.5,16.3"
      />
      <path
        style={{
          fill: 'none',
          stroke: '#2F2E41',
          strokeMiterlimit: 10,
        }}
        d="M211.6,322.2c-24.4-35.7-32.8-69.4-20.2-95.6c13.9-28.9,51-43.7,101.3-44.8"
      />
      <path
        style={{
          fill: 'none',
          stroke: '#2F2E41',
          strokeMiterlimit: 10,
        }}
        d="M725.3,401.9c20.3,32.7,26.6,63.4,15,87.6c-10.2,21.3-33,35-64.4,41.1"
      />
      <path
        style={{
          fill: 'none',
          stroke: '#2F2E41',
          strokeMiterlimit: 10,
        }}
        d="M622.2,299.2c20.4,14.5,39.6,30.4,57.6,47.8"
      />
      <path
        style={{
          fill: 'none',
          stroke: '#2F2E41',
          strokeMiterlimit: 10,
        }}
        d="M198,139.9c40.5-8.7,92.9-7.2,151.5,4.4"
      />
      <path
        style={{
          fill: 'none',
          stroke: '#2F2E41',
          strokeMiterlimit: 10,
        }}
        d="M787.4,401.9c18.1,26.5,29.3,52.1,32.2,75.4"
      />
      <path
        style={{
          fill: 'none',
          stroke: '#2F2E41',
          strokeMiterlimit: 10,
        }}
        d="M598.9,240.7c18.2,10.5,35.5,21.4,51.9,32.6"
      />
      <path
        style={{
          fill: 'none',
          stroke: '#2F2E41',
          strokeMiterlimit: 10,
        }}
        d="M573.3,529.2c-50.1-8.3-106.7-26.2-163.8-53.6c-65.1-31.2-120.2-69.5-159.5-108.2"
      />
      <path
        style={{
          fill: 'none',
          stroke: '#2F2E41',
          strokeMiterlimit: 10,
        }}
        d="M370.3,492.5c-44.8-23.2-85.3-49-120.3-75.9"
      />
      <BubbleOne style={{ fill: '#7865FF' }} cx="53.7" cy="76.1" r="53.7" />
      <BubbleOneOutline
        style={{
          fill: 'none',
          stroke: '#2F2E41',
          strokeMiterlimit: 10,
        }}
        cx="66.3"
        cy="54.2"
        r="53.7"
      />
      <BubbleTwo style={{ fill: '#EAEAEA' }} cx="800.3" cy="270" r="31.2" />
      <BubbleTwoOutline
        style={{
          fill: 'none',
          stroke: '#2F2E41',
          strokeMiterlimit: 10,
        }}
        cx="807.6"
        cy="257.3"
        r="31.2"
      />
      <BubbleThree style={{ fill: '#EAEAEA' }} cx="230.4" cy="595.7" r="44.9" />
      <BubbleThreeOutline
        style={{
          fill: 'none',
          stroke: '#2F2E41',
          strokeMiterlimit: 10,
        }}
        cx="240.9"
        cy="577.4"
        r="44.9"
      />
    </Svg>
  </Wrapper>
)

export default Loader

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100vh - ${THEME.NAV_TOP_HEIGHT}px);
`

const float = keyframes`
	0% {
		transform: translatey(0px);
	}
	50% {
		transform: translatey(-20px);
	}
	100% {
		transform: translatey(0px);
	}
`

const Svg = styled.svg`
  width: 500px;
  height: 400px;

  @media ${THEME.MEDIA_QUERY_MOBILE} {
    width: 167px;
    height: 133px;
  }
`

const G = styled.g`
  animation: ${float} 2000ms infinite ease-in-out;
`

const BubbleOne = styled.circle`
  animation: ${float} 2000ms infinite ease-in-out 100ms;
`

const BubbleOneOutline = styled.circle`
  animation: ${float} 2000ms infinite ease-in-out 250ms;
`

const BubbleTwo = styled.circle`
  animation: ${float} 2000ms infinite ease-in-out 200ms;
`

const BubbleTwoOutline = styled.circle`
  animation: ${float} 2000ms infinite ease-in-out 350ms;
`

const BubbleThree = styled.circle`
  animation: ${float} 2000ms infinite ease-in-out 300ms;
`

const BubbleThreeOutline = styled.circle`
  animation: ${float} 2000ms infinite ease-in-out 450ms;
`
