import * as React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`

interface Props {
  error: string
}

export const Error = <A extends Props>(props: A) => {
  console.trace(props.error)
  return (
    <Wrapper>
      <h1>Error: {props.error.toString()}</h1>
    </Wrapper>
  )
}

export default Error
