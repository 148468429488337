import React, { useState } from 'react'
import { useParams } from 'react-router-dom'

import * as creditorsAPI from '@debbie/cortex/dist/api/creditors'
import { Button, Input, Panel } from '@debbie/iris/dist/components'
import { Container } from '@debbie/iris/dist/components/Grid'
import { ThumbsUp } from '@debbie/iris/dist/components/Icons'

import logo from '../assets/images/logoneg.svg'

const ExperianClientNumber = () => {
  const { creditorId } = useParams<{ creditorId: string }>()
  const [_experianClientId, setExperianClientId] = useState('')
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)

  const {
    tenantName,
    creditorName,
    creditorCentralRegisterId,
    experianClientId,
  } = JSON.parse(
    new URLSearchParams(window.location.search).get('data') || '{}',
  )

  return (
    <Container style={{ width: '700px' }}>
      <br />
      <img src={logo} className="logo" alt="logo" width="160px" />
      <Panel header="Indsend Experian klientnummer">
        <h2>
          {tenantName} med AKS kundenummer: <b>{experianClientId}</b> har
          indgået samarbejde med nedenstående virksomhed. Der anmodes derfor om
          et RKI klientnummer til <i>{creditorName}</i> til fremtidige
          indberetninger.
        </h2>
        <h3>
          Virksomhedsnavn: <i>{creditorName}</i>
        </h3>
        <p>
          CVR: <b>{creditorCentralRegisterId}</b>
        </p>
        {/* <h4>{tenantName}</h4>
        <h3>
          Indtast klientnummer for <i>{creditorName}</i>
        </h3>
        <p>
          CVR: <b>{creditorCentralRegisterId}</b>
        </p>
        {experianClientId && (
          <p>
            Experian kundenummer: <b>{experianClientId}</b>
          </p>
        )} */}
        {success ? (
          <div>
            <h1>
              <ThumbsUp /> Klientnummeret blev registreret
            </h1>
          </div>
        ) : (
          <div>
            <Input
              value={_experianClientId}
              onChange={(e) => {
                setExperianClientId(e.target.value)
              }}
              placeholder="Klientnummer"
              autofocus
            />
            <Button
              onClick={async () => {
                setLoading(true)
                try {
                  if (
                    [creditorCentralRegisterId, experianClientId].includes(
                      _experianClientId,
                    )
                  ) {
                    throw 'error'
                  }
                  await creditorsAPI.experianUpdate(creditorId, {
                    experianClientId: _experianClientId,
                  })
                  setSuccess(true)
                } catch (error) {
                  if (
                    [creditorCentralRegisterId, experianClientId].includes(
                      _experianClientId,
                    )
                  ) {
                    alert(
                      'Klientnummeret kan ikke være identisk med CVR eller Experian kundenummer',
                    )
                  } else {
                    alert(
                      'Der skete en fejl. Prøv venligst igen eller kontakt Debbie',
                    )
                  }
                }
                setLoading(false)
              }}
              loading={loading}
              disabled={
                !(
                  _experianClientId &&
                  _experianClientId.length > 2 &&
                  !isNaN(parseInt(_experianClientId))
                )
              }
            >
              Indsend
            </Button>
          </div>
        )}
      </Panel>
    </Container>
  )
}

export default ExperianClientNumber
