import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

import {
  Button,
  CopyToClipboard,
  Panel,
  PlaceholderLoader,
  Table,
} from '@debbie/iris/dist/components'
import * as collectorsAPI from '@debbie/cortex/dist/api/collectors'
import { Container } from '@debbie/iris/dist/components/Grid'

import logo from '../assets/images/logoneg.svg'
import { CollectorDNSRecord, DNSSetup } from '@debbie/common/dist/collectors'
import THEME from '@debbie/iris/dist/theme'
import { ArrowRight } from '@debbie/iris/dist/components/Icons'

const CollectorDNSSetup = () => {
  const { tenantId, collectorId } = useParams<{
    tenantId: string
    collectorId: string
  }>()

  const [reloading, setReloading] = useState(false)
  const [dns, setDns] = useState<{
    name: string
    verified: boolean
    setup: DNSSetup
  } | null>(null)

  const load = () =>
    collectorsAPI.dnsSetup(tenantId, collectorId).then((res) => {
      setDns(res)
    })

  useEffect(() => {
    if (!dns) {
      load()
    }
  }, [])

  return (
    <Page>
      <Container style={{ width: '900px' }}>
        <br />
        <img src={logo} className="logo" alt="logo" width="160px" />
        {dns && !dns.verified && (
          <Button
            onClick={async () => {
              setReloading(true)
              setDns(null)

              try {
                await load()
              } catch (error) {
                alert('Error checking DNS')
              }
              setReloading(false)
            }}
            loading={reloading}
            style={{ float: 'right' }}
          >
            Check DNS
          </Button>
        )}
        <h1>DNS Setup{dns ? ` - ${dns.name}` : null}</h1>
        {dns?.verified && (
          <Panel bodyStyle={{ backgroundColor: THEME.SUCCESS, color: 'white' }}>
            All records have been confirmed
          </Panel>
        )}
        <Panel>
          {dns ? (
            <div>
              <h2>Domain verification</h2>
              {dns.setup.records
                .filter(
                  (record) => record.description === 'domain-verification',
                )
                .map((record) => (
                  <DNSRecord record={record} />
                ))}
              <h2>E-mail</h2>
              <h3>Outbound e-mails (DKIM & SPF)</h3>
              <p>
                If an SPF record already exists, ensure that you add{' '}
                <code>
                  {
                    dns.setup.records.find((record) =>
                      ['e-mail-spf'].includes(record.description),
                    )?.raw
                  }
                </code>{' '}
                to the existing record.
              </p>
              {dns.setup.records
                .filter((record) =>
                  ['e-mail-spf', 'e-mail-dkim'].includes(record.description),
                )
                .map((record) => (
                  <DNSRecord record={record} />
                ))}
              <h3>Inbound e-mails</h3>
              <p>All inbound mails shall be automatically forwarded.</p>
              <p>
                <b>
                  <CopyToClipboard text={dns.setup.outboundEmail}>
                    {dns.setup.outboundEmail}
                  </CopyToClipboard>{' '}
                  <ArrowRight style={{ margin: '0.2em 0 -0.2em' }} />{' '}
                  <CopyToClipboard text={dns.setup.inboundEmail}>
                    {dns.setup.inboundEmail}
                  </CopyToClipboard>
                </b>
              </p>

              {(dns.setup.creditorWebBase || dns.setup.debtorWebBase) && (
                <>
                  <h2>Sites</h2>
                  <h3>Debtor Web App</h3>
                  {dns.setup.records
                    .filter((record) => 'debtor-webapp' === record.description)
                    .map((record) => (
                      <DNSRecord record={record} />
                    ))}
                  <h3>Creditor Web App</h3>
                  {dns.setup.records
                    .filter(
                      (record) => 'creditor-webapp' === record.description,
                    )
                    .map((record) => (
                      <DNSRecord record={record} />
                    ))}
                </>
              )}
            </div>
          ) : (
            <>
              <br />
              <br />
              <PlaceholderLoader />
            </>
          )}
        </Panel>
      </Container>
    </Page>
  )
}

export default CollectorDNSSetup

const DNSRecord = (props: { record: CollectorDNSRecord }) => {
  const { record } = props

  return (
    <DNSWrapper>
      <Table>
        <tr>
          <th style={{ width: '80px' }}>Verified</th>
          <th style={{ width: '100px' }}>Type</th>
          <th>Hostname</th>
          <th style={{ width: '420px' }}>Value</th>
        </tr>
        <tr>
          <td style={{ textAlign: 'center' }}>
            {record.verified ? '✅' : '❌'}
          </td>
          <td>
            <b>{record.type}</b>
          </td>
          <td>
            <CopyToClipboard text={record.name}>{record.name}</CopyToClipboard>
          </td>
          <td>
            <CopyToClipboard
              style={{
                width: '400px',
              }}
              text={record.value}
            >
              {record.value}
            </CopyToClipboard>
            {record.currentValue && (
              <>
                <br />
                <br />
                <div style={{ fontSize: '0.8em' }}>
                  <b>Current value</b>
                  <br />
                  {record.currentValue}
                </div>
              </>
            )}
          </td>
        </tr>
      </Table>
    </DNSWrapper>
  )
}

const DNSWrapper = styled.div`
  padding: 0 0 40px 0;
  overflow-wrap: break-word;
`

const Page = styled.div`
  overflow-y: auto;
  height: 100vh;
  padding-bottom: 100px;
  background-color: #f1f4fb;
`
